<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Informes Marcas</v-card-title>

      <v-dialog
        v-if="answerModel"
        max-width="700"
        scrollable
        v-model="showEditModal"
      >
        <v-form ref="form" v-model="validEdit" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ editedReport.name }}</span>
            </v-card-title>

            <v-card-text>
              <v-file-input
                show-size
                v-bind:label="`Añadir informe`"
                @change="onReportFileChanged"
                v-model="answerModel._selectedFile"
                accept=".xls,.xlsx,.doc,.docx,.pptx,.ppt,.pdf"
                required
                :rules="[fileRules]"
              >
                <template v-if="answerModel._prevFileName" #label>
                  {{ answerModel._prevFileName }}
                </template>
              </v-file-input>

              <div class="d-flex flex-wrap mt-2">
                <template v-for="(column, k) in answerModel._details">
                  <v-select
                    v-if="column.options && column.options.length"
                    :key="`edited_report_column_${k}`"
                    v-model="column.value"
                    v-bind:label="column.name"
                    :items="column.options"
                    :hide-details="true"
                    filled
                  ></v-select>

                  <v-text-field
                    v-else
                    :key="`edited_report_column_${k}`"
                    class="mr-3"
                    v-model="column.value"
                    :rules="column.isMandatory ? requiredRules : []"
                    v-bind:label="column.name"
                  ></v-text-field>
                </template>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="filterForm" v-model="validFilter">
              <div class="d-flex flex-wrap">
                <v-autocomplete
                  class="mr-4"
                  style="max-width: 500px"
                  v-model="filterMain.brandId"
                  v-bind:label="$t('brands.brand')"
                  v-bind:placeholder="$t('start_typing_to_search')"
                  item-text="name"
                  item-value="id"
                  :search-input.sync="searchBrands"
                  :loading="isSearching['brands']"
                  :items="filteredBrands"
                  hide-no-data
                  hide-selected
                  filled
                  clearable
                  :rules="requiredRules"
                  @change="onChangeBrandFilter"
                ></v-autocomplete>

                <v-autocomplete
                  style="max-width: 500px"
                  v-model="filterMain.brandReportId"
                  v-bind:label="'Nombre Informe'"
                  v-bind:placeholder="$t('start_typing_to_search')"
                  item-text="name"
                  item-value="id"
                  :search-input.sync="searchReports"
                  :loading="isSearching['brand-reports']"
                  :items="filteredReports"
                  hide-no-data
                  hide-selected
                  filled
                  clearable
                  :rules="requiredRules"
                  @change="onChangeReport"
                ></v-autocomplete>
              </div>

              <v-row class="mt-0">
                <v-col cols="12">
                  <v-btn
                    v-if="!isBrand"
                    color="blue"
                    class="mr-2"
                    @click="onNewClick"
                  >
                    Nueva
                  </v-btn>
                  <v-btn color="warning" dark @click="onGoClick">
                    Ir
                    <b-icon
                      icon="arrow-return-right"
                      aria-hidden="true"
                    ></b-icon>
                  </v-btn>
                  <v-btn color="blue" dark class="ml-2" @click="downloadExcel">
                    <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                    Export
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <ve-table
              v-if="isTableActive"
              id="brand-reports-table"
              fixed-header
              :columns="tableHeaders"
              :table-data="tableLines"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              scrollable
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />

            <b-alert show v-else>
              Selecciona un tipo de informe para ver el histórico</b-alert
            >

            <div
              v-show="isTableActive && !tableLines.length"
              class="empty-data"
            >
              No hay informes.
            </div>

            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";
import { logWarning, logInfo, listToDict } from "../../../utils";
import { mapGetters } from "vuex";

export default {
  name: "BrandReports",
  data: function() {
    return {
      // datatable
      table_uid: null, //helper to avoid header reload on every api get that will case state loss
      tableHeaders: [],
      tableLines: [],
      filterMain: {
        brandId: null,
        brandReportId: null
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      totaltableData: 0,
      sorting: null,
      sortOption: {
        sortChange: this.sortChange
      },

      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {}
      },

      answerModel: null,

      isFileSelecting: false,

      editedReport: null,
      showEditModal: false,
      validFilter: true,
      validEdit: true,
      requiredRules: [v => !!v || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 200e6) {
            return true;
          } else {
            return "File size should be less than 5 MB!";
          }
        }
        return true;
      },
      isSearching: {},
      searchBrands: null,
      searchReports: null,
      filteredBrands: [],
      filteredReports: [],
      filterMainUrl: "reporting/brand-reports/getfiltermain",
      filterListUrl: "reporting/brand-reports/getfilterlist",
      isReportSelecting: false,
      filterCriteria: {}
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),

    isTableActive() {
      return this.tableHeaders && this.tableHeaders.length;
    }
  },

  methods: {
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    closeEditDialog() {
      this.showEditModal = false;
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    async onSaveClick() {
      if (!this.validEdit) {
        logWarning("Completa las preguntas obligatorias antes de guardar");
        return;
      }

      if (!this.answerModel._selectedFile && !this.answerModel._prevFileName) {
        logWarning("Añade un informe antes de guardar");
        return;
      }

      let body = _.omit(this.answerModel, [
        "_selectedFile",
        "_details",
        "_prevFileName"
      ]);

      if (this.answerModel._selectedFile) {
        // a new file was uploaded
        let resp = await ApiService.uploadFile(this.answerModel._selectedFile);
        body.fileId = resp.data.id;
      }

      let brandReportAnswerId = _.get(body, "id");

      if (brandReportAnswerId) {
        //update
        await ApiService.put(
          `reporting/brand-reports/answers/${brandReportAnswerId}`,
          body
        );
      } else {
        //create
        let resp = await ApiService.post(
          `reporting/brand-reports/answers`,
          body
        );
        brandReportAnswerId = resp.data.id;
      }

      body = this.answerModel._details.map(el => {
        if (el.value == null) {
          el.value = el.defaultValue;
        }
        return {
          brandReportAnswerId,
          ...el
        };
      });
      await ApiService.post(`reporting/brand-reports/answer-details`, body);

      logInfo("Informe guardado");

      await this.getDataFromApi();

      this.showEditModal = false;
    },

    async onReportFileChanged(file) {
      this.answerModel._selectedFile = file;
    },

    async fetchColumnDefinitions(brandReportId) {
      const resp = await ApiService.get(
        `reporting/brand-reports/${brandReportId}/columns`
      );
      if (!resp.data || !resp.data.length) {
        logWarning("Este informe no tiene definición de columnas");
        throw Error("no column definition");
      }
      return _.sortBy(resp.data, "order");
    },

    async createAnswerModel(answerModel) {
      const brandReportId = this.editedReport.id;

      const columns = await this.fetchColumnDefinitions(brandReportId);

      const detailDict = listToDict(
        _.get(answerModel, "brand_report_answer_details", []),
        "brandReportColumnId"
      );

      const _details = columns.map(col => {
        const found = _.get(detailDict, col.id);

        return {
          id: _.get(found, "id"),
          brandReportColumnId: col.id,
          name: col.name,
          value: _.get(found, "value"),
          isMandatory: !!col.isMandatory,
          defaultValue: col.defaultValue,
          options: col.options
        };
      });
      return {
        id: _.get(answerModel, "id"),
        brandReportId,
        fileId: _.get(answerModel, "fileId"),
        _selectedFile: null,
        _prevFileName: _.get(answerModel, "static.file"),
        _details
      };
    },

    async onDeleteAnswerClick(el) {
      if (window.confirm("Estas seguro que quieres eliminar este informe?")) {
        await ApiService.delete(
          `reporting/brand-reports/answers/${el._answer.id}`
        );
        logInfo("Informe Eliminado");
        await this.onGoClick();
      }
    },

    async fetchReport(brandReportId) {
      const resp = await ApiService.get(
        `reporting/brand-reports/${brandReportId}`
      );
      return resp.data;
    },

    async downloadExcel() {
      if (!this.validFilter) {
        logWarning("Selecciona los filtros primero");
        return;
      }
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      const { brandReportId } = this.filterMain;

      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#brand-reports-table.filterable-column"
      });
      let fetch_url = `reporting/brand-reports/${brandReportId}/downloadexcel`;
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "brand_report_export.xlsx");
    },

    async onNewClick() {
      if (!this.validFilter) {
        logWarning("Selecciona los filtros primero");
        return;
      }
      const { brandReportId } = this.filterMain;
      this.editedReport = await this.fetchReport(brandReportId);
      try {
        this.answerModel = await this.createAnswerModel(null);
        this.showEditModal = true;
      } catch (err) {
        console.error(err);
      }
    },

    async onGoClick() {
      if (!this.validFilter) {
        logWarning("Selecciona los filtros primero");
        return;
      }
      const { brandReportId } = this.filterMain;
      this.editedReport = await this.fetchReport(brandReportId);

      const columns = await this.fetchColumnDefinitions(brandReportId);

      this.filterCriteria = columns.reduce((prev, col) => {
        prev[col.id] = {};
        return prev;
      }, {});

      await this.getDataFromApi();
    },

    async onEditAnswerClick(el) {
      try {
        this.answerModel = await this.createAnswerModel(el._answer);
        this.showEditModal = true;
      } catch (err) {
        console.error(err);
      }
    },

    async getDataFromApi() {
      const { brandReportId } = this.filterMain;

      const { pageIndex, pageSize, sorting, filterCriteria } = this;

      let queryParamsString = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#brand-reports-table.filterable-column"
      });

      // creating headers
      const columns = await this.fetchColumnDefinitions(brandReportId);
      const headers = columns.map(col => {
        return {
          key: col.id.toString(),
          title: col.name,
          field: col.id.toString(),
          align: "left",
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria[col.id]}
                    apiUrl={`${this.filterListUrl}`}
                    columnName={col.id}
                    isFullText={true}
                    filterCriteria={this.filterCriteria[col.id]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "pos")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        };
      });
      headers.unshift({
        key: "report",
        title: "Informe",
        field: "report",
        fixed: "left",
        width: "120px",
        align: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div class="d-flex">
              <div class="mr-2">
                {row.static.fileType === "pdf" && (
                  <b-icon
                    style="color: indianred"
                    scale="1.6"
                    icon="file-earmark-pdf"
                  ></b-icon>
                )}
                {row.static.fileType === "ppt" && (
                  <b-icon
                    style="color: indianred"
                    scale="1.6"
                    icon="file-earmark-ppt"
                  ></b-icon>
                )}
                {row.static.fileType === "doc" && (
                  <b-icon
                    style="color: darkblue"
                    scale="1.6"
                    icon="file-earmark-word"
                  ></b-icon>
                )}
                {row.static.fileType === "xls" && (
                  <b-icon
                    style="color: forestgreen"
                    scale="1.6"
                    icon="file-earmark-excel"
                  ></b-icon>
                )}
              </div>

              <v-btn
                class="mr-1"
                x-small
                on-click={() => this.downloadFile(row.static)}
              >
                {" "}
                descargar{" "}
              </v-btn>
            </div>
          );
        }
      });
      if (this.user.role !== "brand" || this.user.isEdit === "Editar") {
        headers.unshift({
          key: "actions",
          title: "Acciones",
          field: "actions",
          fixed: "left",
          width: 30,
          align: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class="d-flex">
                <button
                  class="btn-outline-danger mr-2"
                  on-click={() => this.onDeleteAnswerClick(row)}
                >
                  <b-icon icon="trash" title="eliminar"></b-icon>
                </button>

                <button
                  class="btn-outline-primary"
                  on-click={() => this.onEditAnswerClick(row)}
                >
                  <b-icon icon="pencil-square" title="eliminar"></b-icon>
                </button>
              </div>
            );
          }
        });
      }

      //creating lines
      let fetchUrl = `reporting/brand-reports/${brandReportId}/answers`;
      if (queryParamsString !== "") {
        fetchUrl += "?" + queryParamsString;
      }
      let resp = await ApiService.get(fetchUrl);

      //extending line props
      let lines = resp.data.map(line => {
        line.static = line._answer.static;
        const ext = line.static.file.split(".").pop();
        line.static.fileType = null;

        if (["pptx", "ppt"].includes(ext)) {
          line.static.fileType = "ppt";
        }
        if (["doc", "docx"].includes(ext)) {
          line.static.fileType = "doc";
        }

        if (["xls", "xlsx"].includes(ext)) {
          line.static.fileType = "xls";
        }

        if (["pdf"].includes(ext)) {
          line.static.fileType = "pdf";
        }

        return line;
      });

      const uid = this.tableHeaders.map(el => el.key).join("_");

      //Table headers: Only reload if necessary to avoid state loss
      if (this.table_uid !== uid) {
        this.tableHeaders = headers;
        this.table_uid = uid;
      }

      this.tableLines = lines;
      this.totaltableData = resp.total;
    },

    resolveUrl(file) {
      return ApiService.getFileUri(file);
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    downloadFile(asset) {
      ApiService.downloadAsset(asset);
    },

    onChangeBrandFilter() {
      this.filterMain.brandReportId = null;
      this.searchValues("reports", "");
    },

    onChangeReport() {},

    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filterMainUrl}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMain };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filteredBrands = resp.data;
      }
      if (fieldname === "reports") {
        this.filteredReports = resp.data;
      }

      this.isSearching[fieldname] = false;
    },

    async init() {
      this.searchValues("brands", "");
    }
  },

  async mounted() {
    await this.init();
  }
};
</script>

<style></style>
